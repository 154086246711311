function getSupplyChainData() {
    return (
        {
        "SupplyChain": { 
            img: "https://www2.deloitte.com/content/dam/insights/articles/ca175268_consulting_supply-chain-equilibrium/primary/CA175268_Banner.jpg/jcr:content/renditions/cq5dam.web.1920.400.jpeg",
            link: "https://www2.deloitte.com/us/en/insights/focus/supply-chain/supply-chain-agility-efficiency.html",
            content: "The new supply chain equilibrium",
            },
        "Government_trend":{ 
            img: "https://www2.deloitte.com/content/dam/insights/articles/us175197_cgi_gov%27t-trends-executive-summary/primary/US175197_Banner.jpg/jcr:content/renditions/cq5dam.web.1920.400.jpeg",
            link: "https://www2.deloitte.com/us/en/insights/industry/public-sector/government-trends/2022/government-rebuilding-post-pandemic-world.html",
            content: "The 10 government trends reshaping the postpandemic world",
            },
        "Emerging_market": { 
            img: "https://www2.deloitte.com/content/dam/insights/articles/us175247_econ-emerging-markets-econ/primary/US175247_Banner.jpg/jcr:content/renditions/cq5dam.web.1920.400.jpeg",
            link: "https://www2.deloitte.com/us/en/insights/economy/emerging-markets-outlook.html",
            content: "Emerging markets",
            },
        "Supply_Chain": { 
            img: "https://www2.deloitte.com/content/dam/insights/articles/us164858_economics-special-topic-october/primary/US164858_Banner.jpg/jcr:content/renditions/cq5dam.web.1920.400.jpeg",
            link: "https://www2.deloitte.com/us/en/insights/economy/us-china-trade-war-supply-chain.html",
            content: "Supply chain resilience in the face of geopolitical risks",
            },
        "Inflation": { 
            img: "https://www2.deloitte.com/content/dam/insights/articles/us175207_econ-econ-special-topic-global-inflation/primary/US175207_Banner.png/jcr:content/renditions/cq5dam.web.1920.400.jpeg",
            link: "https://www2.deloitte.com/us/en/insights/economy/is-the-global-surge-in-inflation-here-to-stay.html",
            content: "Global surge in inflation",
            },
        "Friendshoring": { 
            img: "https://www2.deloitte.com/content/dam/insights/articles/us175188_reshoring-and-friendshoring/primary/US175188_bannerimage.jpg/jcr:content/renditions/cq5dam.web.1920.400.jpeg",
            link: "https://www2.deloitte.com/us/en/insights/industry/public-sector/government-trends/2022/reshoring-global-supply-chains.html",
            content: `Reshoring and "friendshoring" supply chains`,
            },
        "Diversification": { 
            img: "https://www2.deloitte.com/us/en/insights/topics/strategy/supply-chain-diversification/_jcr_content/root/responsivegrid_380572564/advanced_image.coreimg.95.800.jpeg/1647318940013/us175225-figure1.jpeg",
            link: "https://www2.deloitte.com/us/en/insights/topics/strategy/supply-chain-diversification.html",
            content: "how can CFOs prepare for supply chain diversification?",
            },
        "Data Points": { 
            img: "https://www2.deloitte.com/us/en/insights/deloitte-insights-magazine/insights-data-points/_jcr_content/root/responsivegrid_380572564/advanced_image_copy.coreimg.95.800.png/1635352285493/us164873-economy.png",
            link: "https://www2.deloitte.com/us/en/insights/deloitte-insights-magazine/insights-data-points.html",
            content: "Data points: Bite-size insights from Deloitte research",
            },
        "Autonomous_Truck": { 
            img: "https://www2.deloitte.com/content/dam/insights/articles/7031_CIR-Autonomous-trucking-adoption/primary/7031_Banner.jpg/jcr:content/renditions/cq5dam.web.1920.400.jpeg",
            link: "https://www2.deloitte.com/us/en/insights/focus/future-of-mobility/autonomous-trucks-lead-the-way.html",
            content: "Autonomous trucks lead the way",
            },
        "Localization": { 
            img: "https://www2.deloitte.com/content/dam/insights/articles/US154463_CIR-reshoring-and-greenfield-brownfield/primary/US154463_Banner.jpg/jcr:content/renditions/cq5dam.web.1920.400.jpeg",
            link: "https://www2.deloitte.com/us/en/insights/topics/operations/reshoring-supply-chain.html",
            content: "Reshoring or localization on your mind?",
            },
        "Resilient_Health": { 
            img: "https://www2.deloitte.com/content/dam/insights/articles/us164670_chs_health-care-supply-chain/primary/US164670_Banner.jpg/jcr:content/renditions/cq5dam.web.1920.400.jpeg",
            link: "https://www2.deloitte.com/us/en/insights/industry/health-care/healthcare-supply-chain.html",
            content: "Key determinants for resilient health care supply chains",
            },
        "Sizzling_food_price": { 
            img: "https://www2.deloitte.com/content/dam/insights/articles/in164939_econ--economics-special-topic/primary/IN164939_banner%20image.jpg/jcr:content/renditions/cq5dam.web.1920.400.jpeg",
            link: "https://www2.deloitte.com/us/en/insights/economy/global-food-prices-inflation.html",
            content: "Sizzling food prices are leading to global heartburn",
            },
        }
    )
}

export default getSupplyChainData;