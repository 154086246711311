import React, { useState, useEffect } from 'react';
import './nav.scss';
import logo from '../../assets/logo/fs_blue_logo.png';
import MobileNav from '../../assets/common/mobile_nav.png';
import {
    BrowserRouter as Router,
    Routes,
    Route, 
    NavLink,
    useLocation
} from 'react-router-dom';
import Main from '../main/main';
import Solutions from '../solutions/solutions';
import HowItWorks from '../howItWorks/howItWorks';
import Mobility from '../mobility/mobility';
import SupplyChain from '../supplyChain/supplyChain';
import Preparedness from '../preparedness/preparedness';
import ContactUs from '../contactUs/contactUs';
import FinancialNetwork from '../financialNetwork/financialNetwork';
import MilitaryCampaign from '../militaryCampaign/militaryCampaign';

const Nav = () => {
    const [mobileToggle, setMobileToggle] = useState();
    const [mobileOverlay, setMobileOverlay] = useState();
    let location = useLocation();

    const handleResize = () => {
        if (window.innerWidth > 840) {
            setMobileToggle('none');
            setMobileOverlay('none');
        }
    }

    useEffect(() => {
        setMobileToggle('none');
        setMobileOverlay('none');
    },[location])

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    },[])

    const handleMobileNav = () => {
        switch(mobileToggle) {
            case 'slideIn':
                setMobileToggle('slideOut');
                setMobileOverlay(' ');
                return;
            case 'slideOut':
            case 'none':
            default:
                setMobileToggle('slideIn');
                setMobileOverlay('active');
                return;
        }
    }

    const activeSolutions = (name) => {
        if (location.pathname == name) {
            return 'solutionSection active'
        } 
        return 'solutionSection'
    }

    return (
        <>
        <header className="header">
                <NavLink to="/">
                    <span className="header_logo">
                        <img src={logo} alt=""></img>
                        <span className="header_logo_text duborics">FUTURESCAPE</span>
                    </span>
                </NavLink>
                <nav className="header_nav">
                    <NavLink to="./howItWorks" className="nav_section">HOW IT WORKS</NavLink>
                    <div className="solutions_container">
                        <NavLink to="/solutions" id="solutions" className="nav_section">SOLUTIONS</NavLink>
                        <div id="solutionsDropdown" className="box-shadow">
                            <span className={activeSolutions('/mobility')} key="mobility"><NavLink to="./mobility">Mobility</NavLink></span>
                            <span className={activeSolutions('/supplyChain')} key="supplyChain"><NavLink to="./supplyChain">Supply Chain</NavLink></span>
                            <span className={activeSolutions('/preparedness')} key="preparedness"><NavLink to="./preparedness">Preparedness</NavLink></span>
                            <span className={activeSolutions('/financialNetworks')} key="financialNetwork"><NavLink to="./financialNetworks">Financial Networks</NavLink></span>
                            <span className={activeSolutions('/militaryCampaignAnalytics')} key="militaryCampaign"><NavLink to="./militaryCampaignAnalytics">Military Campaign Analytics</NavLink></span>
                        </div>
                    </div>
                    <NavLink to="/contactUs">
                        <button className="demo_btn">REQUEST DEMO</button>
                    </NavLink>
                </nav>
                <nav className="mobile_nav" onClick={handleMobileNav}>
                    <img src={MobileNav} alt=""></img>
                </nav>
                <div className={`overlay ${mobileOverlay}` } onClick={handleMobileNav}></div>
                <div className={`mobile_nav_content ${mobileToggle}`}>
                    <div className="wrapper">
                        <span className="duborics header_1">MENU</span>
                        <div className="menu_content">
                            <li><NavLink to="/">MAIN</NavLink></li>
                            <li><NavLink to="./howItWorks">HOW IT WORKS</NavLink></li>
                            <li><NavLink to="./solutions">SOLUTIONS</NavLink></li>
                            <ul>
                                <li><NavLink to="./mobility">Mobility</NavLink></li>
                                <li><NavLink to="./supplyChain">Supply Chain</NavLink></li>
                                <li><NavLink to="./preparedness">Preparedness</NavLink></li>
                                <li><NavLink to="./financialNetworks">Financial Networks</NavLink></li>
                                <li><NavLink to="./militaryCampaignAnalytics">Military Campaign Analytics</NavLink></li>
                            </ul>
                            <li><NavLink to="./contactUs">REQUEST DEMO</NavLink></li>
                        </div>
                    </div>
                </div>
        </header>

        <Routes>
            <Route path="/home" element={<Main />}></Route>
            <Route path="/" element={<Main />}></Route>
            <Route path="/howItWorks" element={<HowItWorks />}></Route>
            <Route path="/solutions" element={<Solutions />}></Route>
            <Route path="/mobility" element={<Mobility />}></Route>
            <Route path="/supplyChain" element={<SupplyChain />}></Route>
            <Route path="/financialNetworks" element={<FinancialNetwork />}></Route>
            <Route path="/militaryCampaignAnalytics" element={<MilitaryCampaign />}></Route>
            <Route path="/preparedness" element={<Preparedness />}></Route>
            <Route path="/contactUs" element={<ContactUs />}></Route>
        </Routes>
        </>
    )
}

export default Nav;