import agentBased from '../../assets/howItWorks/agentBased.png';
import overview from '../../assets/howItWorks/overview.png';
import cloudNative from '../../assets/howItWorks/cloudNative.png';
import engine from '../../assets/howItWorks/engine.png';

function generateSliderData() {
    return ({

        "overview": {
            "content": "FutureScape was built from the ground-up to be fast and scalable. We use agent-based simulation to reliably model the complex ripple effects of the real world. Its cloud-based architecture and distributed computing power quickly deliver simulations that scale to the breadth and complexities of even the most interconnected and massive systems.",
            "image": overview,
            "name": "Overview"
        },
        "agentBased": {
            "content": "FutureScape equips simulated agents with decision-making capabilities to understand how they respond to changes or disruptions within their environment. This powerful approach allows organizations to study the ripple effects of macro-scale changes at the individual level.",
            "image": agentBased,
            "name": "Agent-Based"
        },
        "cloudNative": {
            "content": "Simulating dense, massive-scale systems are too complex for a single computer. FutureScape is cloud-native and cloud-agnostic to overcome the limitations of hardware without compromising speed, scale, or fidelity.",
            "image": cloudNative,
            "name": "Cloud-Native"
        },
        "patentedEngine": {
            "content": "FutureScape’s compute engine powers full-scale, complex simulations without sacrificing speed or fidelity. This patented technology makes it possible to employ numerous cloud computers at once to process high-speed, high-fidelity simulations. This allows organizations to run multiple simulations rapidly, iteratively, and simultaneously.",
            "image": engine,
            "name": "Patented Engine"
        }
    });
} 

export default generateSliderData;