import OptimizedContentImage from '../../assets/supplyChain/OptimizedResources.png';
import PredictiveDataImage from '../../assets/supplyChain/PredictiveData.png';
import DecisionAdvantageImage from '../../assets/supplyChain/DecisionAdvantage.png';

function generateSupplyChainSliderData() {
    return ({
        "optimizedResources": {
            "content": "With FutureScape, you can access where to focus your supply chain efforts for the greatest impact. FutureScape pairs supply chain illumination data and with its library of simulations to assess the impact of future events on multi-tier supply chain networks.",
            "image": OptimizedContentImage,
            "name": "Optimized Resources",
            "quote": `"I need to make the most of my limited time, money, and resources."`
        },
        "predictiveData": {
            "content": "FutureScape models your current supplier network as a baseline, and then runs additional models to simulate the potential impact of disruptions on your current environment. The simulation-aided analysis fills in data gaps, providing you with insights into what could happen as disruptions with your supply chain network.",
            "image": PredictiveDataImage,
            "name": "Lack of Predictive Data",
            "quote": `"I do not know how to answer my 'what-if' questions."`
        },
        "decisionAdvantage": {
            "content": "Clients can configure different scenario types, such as sanctions, natural disasters, and geo-political events, for simulation to measure their potential impact on your supply chain. FutureScape - technology and team - helps you iteratively test potential disruptions to design mitigation strategies that harden against your risk.",
            "image": DecisionAdvantageImage,
            "name": "Decision Advantage",
            "quote": `"I do not even know what disruptions to look for."`
        }
    })
}

export default generateSupplyChainSliderData;