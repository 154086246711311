import dataIngest from '../../assets/howItWorks/data_ingest.png';
import simulation from '../../assets/howItWorks/simulation.png';
import baseline from '../../assets/howItWorks/baseline.png';
import result from '../../assets/howItWorks/result.png';

function generateFile() {
    return ({

    "collect": {
        "step": "Step 1 | Collect & Ingest Data: ",
        "description": "We love all data equally. Our flexible intake pipeline can ingest open source, third-party, and client-provided data in nearly any format.",
        "image": dataIngest
    },
    "baseline": {
        "step": "Step 2 | Build a Baseline: ",
        "description": "A validated baseline model is vital to a trustworthy simulation. We validate and calibrate baseline simulations to limit tuning and debugging later.",
        "image": baseline,
    },
    "simulation": {
        "step": "Step 3 | Run Simulations: ",
        "description": "FutureScape simulates proposed system changes and generates high-fidelity data about the impact of these changes so we can track and measure what happens.",
        "image": simulation,
    },
    "result": {
        "step": "Step 4 | Analyze & Visualize Results: ",
        "description": "Having data is half the battle; understanding it is the other. We work with you to analyze simulation data and answer your most important “what if” questions.",
        "image": result,
    }
    });
} 

export default generateFile;