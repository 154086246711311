const SliderController = ({ activeId, mobileView, handleSlider, content }) => {

    const handleSliderType = (content, index, name) => {
            if (mobileView) {
                return (<span
                    onClick={() => handleSlider(index)}
                    className={"mobile_control_section" + (activeId == index ? ' active' : '')}
                />
                )
            } else {
                return (<span
                        onClick={() => handleSlider(index)}
                        className={"control_section text" + (activeId == index ? ' active' : '')}
                    >{name}</span>
                )
            }
    }

    return (
        <div className="controller">
            {
                Object.keys(content).map((key, index) => {
                    return (
                        <div key={"controller_" + index}>
                            {handleSliderType(content, index, content[key].name)}
                        </div>
                    )
                })
            }
        </div>
    )
}
export default SliderController;