import './App.css';
import Nav from './components/nav/nav';
import Footer from './components/footer/footer';
import { BrowserRouter } from 'react-router-dom';
import { AuthService } from './auth';
import NavWrapper from './components/navWrapper/navWrapper';

function App() {
  const auth = new AuthService();
  auth.logInGuest().then(_ => console.log('Back in the App function.')).catch(e => console.error('Error:', e));

  return (
    <BrowserRouter>
      <NavWrapper>
          <Nav />
          <Footer />
      </NavWrapper>
    </BrowserRouter>
  );
}

export default App;
