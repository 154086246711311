const QuoteBox = ({children}) => {
    return (
        <div className="quoteBox">
            <div className="borderLeft"></div>
            <div className="borderTopRight"></div>
            <div className="borderTopBottom"></div>
            <div className="borderBottomLeft"></div>
            <div className="quote_box_content text">
                { children }
            </div>
        </div>
    )
}

export default QuoteBox;