import React, { useEffect, useState, useRef } from 'react';
import sectionDivider from '../../assets/solutions/section_divider.png';
import fs_preparedness from '../../assets/solutions/details/futurescape_preparedness.png';
import LinkGrid from '../linkGrid/linkGrid';
import Scroll from '../scroll/scroll';
import getPreparednessData from '../../data/preparedness.js';
import ContactSection from '../contactSection/contactSection';
import QuoteBox from '../quoteBox/quoteBox';

const Preparedness = () => {
    const [preparednessData, setPreparednessData] = useState({});
    const introRef = useRef();
    const contactUsRef = useRef();
    const preparednessRef = useRef();
    const leadershipRef = useRef();

    useEffect(() => {
        let data = getPreparednessData();
        setPreparednessData(data)
    },[])

    return (
        <div>
        <div id="overlay"></div>
        <section>
            <section id="intro" className="section" ref={introRef}> 
                <div className="middle_wide auto">
                    <span className="catchphrase duborics text_middle">
                        Industry Solutions: <br/> Preparedness
                    </span>
                    <QuoteBox>
                        Cities, their layered infrastructure, and the residents that depend on them are
                        becoming increasingly interconnected. Preparing for natural disasters is challenging,
                        especially without data on the ripple effects of potential disruptions.
                        Today’s preparedness efforts require quantitative solutions that move beyond traditional simulation methods.
                        <br/><br/>
                        FutureScape models entire cities, including the people within them. We give agencies predictive insights to
                        help power people-first planning.
                    </QuoteBox>
                </div>
            </section>
            <section id="preparedness" className="section" ref={preparednessRef}>
                <div className="solution_section_title">
                    <span className="header_3">Futurescape for preparedness</span>
                    <img src={sectionDivider} alt=""></img>
                </div>
                <div className="section_content text">
                    <span>
                        FutureScape can build a digital twin of a city or region, 
                        including its entire population. We add AI-driven behaviors
                        that govern each layer to evaluate the connection points and 
                        relationships that are impacted when a natural disaster or disruption occurs.
                        <br/><br/>
                        FutureScape provides organizations with a novel way to digitally 
                        explore and test how outages and disruptions would impact the operations of an entire city and its population.
                    </span>
                    <div className="solution_content_box_row">
                        <div className="solution_box white">
                            <span className="bold">
                                FutureScape for <span className="green">Preparedness</span> models multiple interconnected layers...
                            </span>
                            <ul className="light">
                                <li>Road traffic</li>
                                <li>Telecommunications</li>
                                <li>Power infrastructure</li>
                                <li>Residents with AI-driven decision-making capabilities</li>
                            </ul>
                            <span className="bold">...to create a holistic understanding of how a disruption or natural disaster will cascade through a region.</span>
                        </div>
                        <div className="solution_content_box_image">
                            <img src={fs_preparedness} alt=""></img>
                        </div>
                    </div>
                </div>
            </section>
            <section id="leadership" className="section" ref={leadershipRef}>
                <div className="solution_section_title">
                    <span className="header_3">Thought leadership</span>
                    <img src={sectionDivider} alt=""></img>
                </div>
                <LinkGrid 
                    data={preparednessData}
                />
            </section>
        </section>
        <section id="contactUs" ref={contactUsRef}>
            <ContactSection />
        </section> 
        <Scroll
            scrollSection={[
                introRef, 
                preparednessRef, 
                leadershipRef, 
                contactUsRef
            ]}
        />
    </div>
    )
}

export default Preparedness;