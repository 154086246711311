function getFinancialNetworkData() {
    return ({
        'industry_outlook':{
                img:"https://www2.deloitte.com/content/dam/insights/articles/US164650_CFS-Insurance-industry-outlook/primary/US164650_Banner.jpg/jcr:content/renditions/cq5dam.web.1920.400.jpeg",
                link: "https://www2.deloitte.com/us/en/insights/industry/financial-services/financial-services-industry-outlooks/insurance-industry-outlook.html",
                content: "2022 insurance industry outlook"
                },
        'market_outlook': {
                img:"https://www2.deloitte.com/content/dam/insights/articles/US164678_CFS-banking-and-capital-markets-outlook/primary/US164678_Banner.jpg/jcr:content/renditions/cq5dam.web.1920.400.jpeg",
                link: "https://www2.deloitte.com/us/en/insights/industry/financial-services/financial-services-industry-outlooks/banking-industry-outlook.html",
                content: "2022 banking and capital markets outlook"
                },
        'banking_momentum': {
                img:"https://www2.deloitte.com/content/dam/insights/articles/us134194_cfs-digital-banking-stick/primary/US134194_banner.png/jcr:content/renditions/cq5dam.web.1920.400.jpeg",
                link: "https://www2.deloitte.com/us/en/insights/industry/financial-services/digitalization-in-banking.html",
                content: "Building on the digital banking momentum"
                },
        'financial_planning_challenge': {
                img:"https://www2.deloitte.com/content/dam/insights/articles/7158_finance-transformation-charticle_capabilities/primary/7158_Banner.jpg/jcr:content/renditions/cq5dam.web.1920.400.jpeg",
                link: "https://www2.deloitte.com/us/en/insights/topics/strategy/sales-and-revenue-forecasting-during-uncertainty.html",
                content: "Capabilities overtake capacity as top financial planning challenge, CFO poll shows"
                },
        'tax operations': {
                img:"https://www2.deloitte.com/content/dam/insights/articles/us154484_cfs_operate-to-transform/primary/US154484_Banner.png/jcr:content/renditions/cq5dam.web.1920.400.jpeg",
                link: "https://www2.deloitte.com/us/en/insights/industry/financial-services/tax-operating-model-transformation.html",
                content: "Is it time to strategically transform tax operations?"
                },
        'friendshoring': {
                img:"https://www2.deloitte.com/content/dam/insights/articles/us175188_reshoring-and-friendshoring/primary/US175188_bannerimage.jpg/jcr:content/renditions/cq5dam.web.1920.400.jpeg",
                link: "https://www2.deloitte.com/us/en/insights/industry/public-sector/government-trends/2022/reshoring-global-supply-chains.html",
                content: `Reshoring and "friendshoring" supply chains`
                },
        'supply_chain': {
                img:"https://www2.deloitte.com/us/en/insights/topics/strategy/supply-chain-diversification/_jcr_content/root/responsivegrid_380572564/advanced_image.coreimg.95.800.jpeg/1647318940013/us175225-figure1.jpeg",
                link: "https://www2.deloitte.com/us/en/insights/topics/strategy/supply-chain-diversification.html",
                content: "How can CFOs prepare for supply chain diversification?"
                },
        'data_points': {
                img:"https://www2.deloitte.com/us/en/insights/deloitte-insights-magazine/insights-data-points/_jcr_content/root/responsivegrid_380572564/advanced_image_copy.coreimg.95.800.png/1635352285493/us164873-economy.png",
                link: "https://www2.deloitte.com/us/en/insights/deloitte-insights-magazine/insights-data-points.html",
                content: "Data points: Bite-size insights from Deloitte research"
                },
        }
    )
}

export default getFinancialNetworkData;