import {
    AuthenticationDetails,
    CognitoAccessToken,
    CognitoIdToken,
    CognitoRefreshToken,
    CognitoUser,
    CognitoUserPool,
    CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { CognitoIdentityProviderClient } from "@aws-sdk/client-cognito-identity-provider";
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface AuthState {
    isLoggedIn: boolean;
    username: string | null;
    id: string | null;
    email: string | null; // This may not be available if some other form of authorization was used
}

const USER_POOL = new CognitoUserPool({
    UserPoolId: 'us-east-2_79bcY6C6c',
    ClientId: '7olj9c8fc2g88ie2frso2djdvt',
});

const IDENTITY_SERVICE_PROVIDER = new CognitoIdentityProviderClient({ region: 'us-east-2' });
const REFRESH_TIMER_BUFFER = 2 * 60 * 1000;

const GUEST_USERNAME = 'visitor@deloitte.com';
const GUEST_PASSWORD = 'V!s!t0rP@ss';

export class AuthService {
    private _auth: BehaviorSubject<AuthState>;
    readonly auth$: Observable<AuthState>;

    private _currentSession: CognitoUserSession;
    private _currentCognitoUser: CognitoUser;

    /** Observe the isLoggedIn slice of the auth state */
    readonly isLoggedIn$: Observable<boolean>;
    readonly isLoggedOut$: Observable<boolean>;

    private _refreshTokenTimeout: any;

    constructor() {
        this._auth = new BehaviorSubject<AuthState>( null );
        this.auth$ = this._auth.asObservable();
        this.isLoggedIn$ = this.auth$.pipe(
            map( state => state?.isLoggedIn )
        );
        this.isLoggedOut$ = this.isLoggedIn$.pipe(
            map( isLoggedIn => !isLoggedIn ),
        );

        this._resetAuthUser();
        this._refreshTokenTimeout = 0;
        // console.log('auth service created');
    }

    get isLoggedIn(): boolean {
        return !!this._auth.value?.isLoggedIn;
    }

    private _resetAuthUser() {
        let cognitoUser = USER_POOL.getCurrentUser();

        if (cognitoUser) {
            cognitoUser.getSession((err: any, session: any) => {
                this._currentSession = session;
                if (err) {
                    console.error(err.message || JSON.stringify(err));
                    this._setUser(null);
                    this.stopRefreshTokenTimer();
                    return;
                }
                this._setUser(session?.isValid() ? cognitoUser : null);
                // console.log(`Access Token expiration time:`, (new Date(this.getAccessToken().getExpiration() * 1000)).toLocaleTimeString());
                this.startRefreshTokenTimer();
            });
        } else {
            this._setUser(null)
            this.stopRefreshTokenTimer();
        }
    }

    getJwtToken(): string {
        return this._currentSession?.getAccessToken()?.getJwtToken();
    }

    getAccessToken(): CognitoAccessToken {
        return this._currentSession?.getAccessToken();
    }

    getIdToken(): CognitoIdToken {
        return this._currentSession?.getIdToken();
    }

    getRefreshToken(): CognitoRefreshToken {
        return this._currentSession?.getRefreshToken();
    }

    logInGuest(): Promise<boolean> {
        // console.log('Logging in guest');
        let authenticationDetails = new AuthenticationDetails( {
            Username: GUEST_USERNAME,
            Password: GUEST_PASSWORD,
        } );

        const userData = {
            Username: GUEST_USERNAME,
            Pool: USER_POOL
        };

        const cognitoUser = new CognitoUser( userData );
        return new Promise( (resolve, _) =>
            cognitoUser.authenticateUser( authenticationDetails, {
                onSuccess: _ => {
                    // console.log('successfully logged in guest');
                    this._resetAuthUser();
                    return resolve( true );
                },
                onFailure: (err: any) => {
                    console.error( err.message || JSON.stringify( err ) );
                    resolve( false );
                },
                newPasswordRequired: async function (userAttributes, requiredAttributes) {
                    delete userAttributes.email_verified;
                    delete userAttributes.phone_number_verified;
                    userAttributes.name = authenticationDetails.getUsername();
                    userAttributes.nickname = authenticationDetails.getUsername();

                    try {
                        cognitoUser.completeNewPasswordChallenge(
                            GUEST_PASSWORD,
                            userAttributes, this // Yes, this is recursive, which doesn't make any sense, but it's the only thing that works!
                        );
                        return resolve( true );
                    } catch (err) {
                        console.error( 'Something went wrong completing new password challenge:', err );
                        return resolve( false );
                    }
                }
            } )
        );
    }

    private _setUser(cognitoUser: CognitoUser) {
        this._currentCognitoUser = cognitoUser;

        if (!this._currentCognitoUser) {
            this._auth.next(null);
            return;
        }

        this._currentCognitoUser.getUserData((err: any, result) => {
            if (err) {
                console.error('Error fetching user data: ', err.message || JSON.stringify(err));
                this._auth.next(null);
                return;
            }

            const attributes = {
                sub: '',
                name: '',
                email: '',
            };

            result.UserAttributes.forEach(
                (attribute: {Name: string, Value: string}) => {
                    attributes[attribute.Name] = attribute.Value;
                });

            this._auth.next({
                isLoggedIn: true,
                id: attributes.sub,
                username: attributes.name,
                email: attributes.email,
            });
        });
    }

    refreshSession() {
        const refresh_token = this.getRefreshToken();
        if (refresh_token) {
            try {
                this._currentCognitoUser.refreshSession(refresh_token, (err, session) => {
                    if (err) {
                        console.error(err);
                        this.stopRefreshTokenTimer();
                    } else {
                        this._currentSession = session;
                        this.startRefreshTokenTimer();
                    }
                });
            } catch (err) {
                // console.log('Failed to refresh session:', err);
                this.stopRefreshTokenTimer();
            }
        } else {
            this.stopRefreshTokenTimer();
        }
    }

    private startRefreshTokenTimer() {
        this.stopRefreshTokenTimer();

        // set a timeout to refresh the token 5 minutes before it expires
        const expires = new Date(this.getAccessToken().getExpiration() * 1000);
        const timeout = expires.getTime() - Date.now() - REFRESH_TIMER_BUFFER;
        this._refreshTokenTimeout = setTimeout( () => {
            this.refreshSession();
        }, timeout);
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this._refreshTokenTimeout);
    }

}
