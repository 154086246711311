import './contactUs.scss';

const Submitted = () => {
    return (
        <div className="submitted">
            <span className="header_3 duborics">Thank you for your interest.</span>
            <br></br>
            <span className="header_4 light">You will be redirected momentarily.</span>
        </div>
    )
}

export default Submitted;