function getPreparednessData() {
    return ({
        "climate_resilient_city": {
            img: "https://www2.deloitte.com/content/dam/insights/articles/in164668_cgi-climate-resilient-cities/primary/IN%20164668_Banner.jpg/jcr:content/renditions/cq5dam.web.1920.400.jpeg",
            link: "https://www2.deloitte.com/us/en/insights/industry/public-sector/climate-resilient-cities.html",
            content: "Building climate-resillient cities",
        },
        'disruption':{
            img: "https://www2.deloitte.com/content/dam/insights/articles/7203_CIO-enterprise-resilience/primary/7203_Banner.jpg/jcr:content/renditions/cq5dam.web.1920.400.jpeg",
            link: "https://www2.deloitte.com/us/en/insights/topics/leadership/enterprise-resilience-leading-through-disruption.html",
            content: "Leading through the fog of disruption",
        },
        "resilience": {
            img: "https://www2.deloitte.com/content/dam/insights/us/articles/6800_thriving-post-covid/images/6800_banner.png/jcr:content/renditions/cq5dam.web.1440.660.jpeg",
            link: "https://www2.deloitte.com/us/en/insights/economy/covid-19/post-covid-restart-or-new-start.html",
            content: "New architecutres of resilience",
        },
        'government_trend': {
            img: "https://www2.deloitte.com/content/dam/insights/articles/us175197_cgi_gov%27t-trends-executive-summary/primary/US175197_Banner.jpg/jcr:content/renditions/cq5dam.web.1920.400.jpeg",
            link: "https://www2.deloitte.com/us/en/insights/industry/public-sector/government-trends/2022/government-rebuilding-post-pandemic-world.html",
            content: "The 10 government trends reshaping the postpandemic worlm",
        },
        "social_care": {
            img: "https://www2.deloitte.com/content/dam/insights/articles/us175191_cgi_gov%27t-trends-re-imagining-social-care/primary/US175191_Banner.jpg/jcr:content/renditions/cq5dam.web.1920.400.jpeg",
            link: "https://www2.deloitte.com/us/en/insights/industry/public-sector/government-trends/2022/recasting-social-safety-net.html",
            content: "Reimagining social care",
        },
        'big_agenda':{
            img: "https://www2.deloitte.com/content/dam/insights/articles/us164625_big-agenda/primary/US164625_banner.jpg/jcr:content/renditions/cq5dam.web.1920.400.jpeg",
            link: "https://www2.deloitte.com/us/en/insights/topics/strategy/current-business-problems-strategic-imperatives.html",
            content: "Your big agenda just got bigger",
        },
        'friendshoring': {
            img: "https://www2.deloitte.com/content/dam/insights/articles/us175188_reshoring-and-friendshoring/primary/US175188_bannerimage.jpg/jcr:content/renditions/cq5dam.web.1920.400.jpeg",
            link: "https://www2.deloitte.com/us/en/insights/industry/public-sector/government-trends/2022/reshoring-global-supply-chains.html",
            content: `Reshoring and "friendshoring" supply chains`,
        },
        'public_health': {
            img: "https://www2.deloitte.com/content/dam/insights/articles/us175190_cgi_gov%27t-trends-rise-of-global-public-health/primary/US175190_Banner.png/jcr:content/renditions/cq5dam.web.1920.400.jpeg",
            link: "https://www2.deloitte.com/us/en/insights/industry/public-sector/government-trends/2022/global-health-partnerships-collaboration.html",
            content: "New era of global public health partnership",
        }
    })
}

export default getPreparednessData;