import React, { useState, useEffect } from 'react';
import './flipCard.scss';

const FlipCard = (content) => {
    const [activeId, setActiveId] = useState(0);

    const handleFlip = (index) => {
        setActiveId(index);
    }

    return (
        <>
            <div className="flip_card_container">
                <div className="flip_cards">
                {
                    Object.keys(content.data).map((key, index) => {
                        return (
                            <div 
                                key={"flip_card_" + index}
                                className={"flip_card" + (activeId == index ? " active" : "")} 
                                onClick={() => handleFlip(index)}
                            >
                                <div className="hexagon">
                                    <img src={content.data[key].image} alt="" />
                                </div>
                                <div className="horizontal_line"></div>
                                <span className="circle"></span>
                            </div>
                        )
                    })
                }
                </div>
                <div className="vertical_line"></div>
                <div className="card_contents">
                    {
                        Object.keys(content.data).map((key, index) => {
                            return (
                                <div key={"card_content_" + index}className={"card_content" +  (activeId == index ? " active" : "")} onClick={() => handleFlip(index)}>
                                    <span className="step text">
                                        { content.data[key].step }
                                    </span>
                                    <span className="text">
                                        { content.data[key].description }
                                    </span>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className="mobile_flip_card_container">
                {
                    Object.keys(content.data).map((key, index) => {
                        return (
                            <div key={"cardRow_" + index} className={"cardRow" + (index % 2 == 0 ? " even" : "")}>
                                <div className="card_content">
                                    <div className="step">{ content.data[key].step }</div>
                                    <div className="text">{ content.data[key].description }</div>
                                </div>
                                <div className="flip_card">
                                    <div className="hexagon">
                                        <img src={content.data[key].image} alt="" />
                                    </div>
                                    <div className="horizontal_line"></div>
                                    <span className="circle"></span>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}

export default FlipCard;