import React, { useState, useEffect } from 'react';
import './slider.scss';
import SliderController from "./sliderController";

const Slider = (props) => {
    const [activeId, setActiveId] = useState(0);
    const [touchStartX, setTouchStartX] = useState();
    const [mobileView, setMobileView] = useState(false);

    const handleSlider = (index) => {
        setActiveId(index);
    }

    const handleSwipeEnd = (e) => {
        if (touchStartX - e.clientX > 0) {
            setActiveId((index) => index == Object.keys(props.data).length - 1 ? index : index + 1);
        } else {
            setActiveId((index) => index == 0 ? index: index - 1);
        }
    }

    const handleResize = () => {
        if (window.innerWidth > 840) {
            setMobileView(false);
        } else {
            setMobileView(true);
        }
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    },[])

    return (
        <div className="slider_container">
            <SliderController
                mobileView={mobileView}
                activeId={activeId}
                handleSlider={handleSlider}
                content={props.data}
            />
            <div className="slider">
            { Object.keys(props.data).map((key, index) => {
                    const slideDetail = props.data[key];
                    return (
                        <div key={'slider_'+ index} className={"slide" + (activeId == index ? ' active' : '') }>
                            { mobileView ?
                                <div className="slide_container" key={key}
                                onTouchStart={(e) => setTouchStartX(e.changedTouches[0].clientX) }
                                onTouchEnd={(e) => handleSwipeEnd(e.changedTouches[0])}
                                >
                                    <div className="slide_content">
                                        <div className="quote">{slideDetail.quote}</div>
                                        <span className="text">{slideDetail.content}</span>
                                    </div>
                                    <div className="slide_content_image">
                                    <img src={slideDetail.image} alt="" />
                                    </div>
                                </div>
                                :
                                <div className="slide_container" key={key} >
                                    <div className="slide_content">
                                        <div className="quote">{slideDetail.quote}</div>
                                        <span className="text">{slideDetail.content}</span>
                                    </div>
                                    <div className="slide_content_image">
                                        <img src={slideDetail.image} alt="" />
                                    </div>
                                </div>
                            }
                        </div>
                    )
                })
                }
            </div>
    </div>
    )
}

export default Slider;