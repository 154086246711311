import React, { useRef, useEffect, useState } from 'react';
import './howItWorks.scss';
import Scroll from '../scroll/scroll';
import contactUs from '../../assets/common/contactUs.png';
import generateFile from './flipCardData';
import generateSliderData from './sliderData';
import Slider from '../slider/slider';
import one_Icon from '../../assets/howItWorks/01_Icon.png';
import two_Icon from '../../assets/howItWorks/02_Icon.png';
import three_Icon from '../../assets/howItWorks/03_Icon.png';
import FlipCard from '../flipCard/flipCard';
import ContactSection from '../contactSection/contactSection';

const HowItWorks = () => {
    const introRef = useRef();
    const whatWeDoRef = useRef();
    const whatWeBringRef = useRef();
    const howItWorksRef = useRef();
    const contactUsRef = useRef();
    const [sliderData, setSliderData] = useState({});
    const [flipCardData, setFlipCardData] = useState({});

    useEffect(() => {
        let flipCardData = generateFile();
        let sliderData = generateSliderData();
        setSliderData(sliderData);
        setFlipCardData(flipCardData);
    },[])

    return (
        <>
        <section>
            <section id="intro" className="section" ref={introRef}>
                <div className="middle_wide auto">
                    <span className="catchphrase text_middle duborics">Simulation Analytics <br /> & Services</span>  
                    <span className="section_summary text">
                        The power of FutureScape<sup className="TM">TM</sup> goes beyond its tech stack. We pair patented technology with an advanced analytics team 
                        to help you go from data to decisions quickly, allowing your organization to plan, strategize, prepare, and operate with confidence.
                        <br></br><br></br>
                        What could you do with predictive insights for your most complex systems? Let’s find out.
                    </span>
                </div>
            </section>
            <section id="whatWeDo" className="section" ref={whatWeDoRef}>
                <div className="section_title centerLeftAlign">
                    <img src={one_Icon} alt="" className="number_section_img"></img>
                    <span className="header_3 number_section">WHAT WE DO</span>
                </div>
                <Slider data={sliderData} />
            </section>
            <section id="howItWorks" className="section" ref={howItWorksRef}>
                <div className="section_title centerLeftAlign">
                    <img src={two_Icon} alt=""  className="number_section_img"></img>
                    <span className="header_3 number_section">HOW IT WORKS</span>
                </div>
                <div className="section_content">
                    <span className="text">
                        At FutureScape, we deliver the power of simulation without the unknowns of a “black box” approach. 
                        Our team of specialists works closely with clients to take them on the journey with us: from evaluating 
                        the pros and cons of data sources, designing test scenarios, calibrating and validating results, and
                        identifying game-changing insights.
                    </span>
                    <FlipCard data={flipCardData} />
                    <div className="mobile_flip_card_container"></div>
                </div>
            </section>
            <section id="whatWeBring" className="section" ref={whatWeBringRef}>
                <div className="section_title centerLeftAlign">
                    <img src={three_Icon} alt=""  className="number_section_img"></img>
                    <span className="header_3 number_section">WHAT WE BRING</span>
                </div>
                <div className="section_content">
                    <div className="professional_services white light text">
                        <span className="box_title">
                            <span>Professional</span>
                            <span>Services</span>
                        </span>
                        Deloitte, a worldwide leader in data and analytics services,<sup>1</sup> provides professional services in advanced analytics, 
                        modeling and simulation, and data science. We go beyond “software in a box” to help organizations make
                        the most out of FutureScape’s simulation-powered insights.
                        <span className="citation">
                            <a 
                                target="_blank"
                                href="https://www2.deloitte.com/global/en/pages/technology/articles/magic-quadrant-for-data-and-analytics-service.html?nc=1">
                                1 Deloitte named leader in Gartner's Magic Quadrant™ for Data and Analytics Service Providers in 2022
                            </a>
                        </span>
                    </div>
                    <div className="alliance_relationships darkGray text">
                        <div className="divider"></div>
                        <span className="box_title">
                            <span>Alliance</span>
                            <span>Relationships</span>
                        </span>
                        Deloitte’s relationships with some of the world’s leading cloud and data providers can accelerate your goals.
                        Let us connect you to our trusted ecosystem to find alliances that fit your unique goals. 
                    </div>
                </div>
                <div className="quoteBox">
                    <div className="borderLeft"></div>
                    <div className="borderTopRight"></div>
                    <div className="borderTopBottom"></div>
                    <div className="borderBottomLeft"></div>
                    <div className="quote_box_content">
                        <span className="italic text">
                        “We specifically were seeking a firm that would help us 
                        translate complex simulation and modeling data for 
                        decision-makers and work with multiple agency partners 
                        to bring them "on-board"...Deloitte was able to deliver 
                        this in a seamless and well-balanced package with their 
                        FutureScape team.” 
                        </span>
                        <span className="author text">&#8212; Gartner Peer Insights</span>
                    </div>
                </div> 
            </section>
        </section>
        <section id="contactUs" ref={contactUsRef}>
            <ContactSection />
        </section>

        <Scroll
                scrollSection = {[introRef, whatWeDoRef, howItWorksRef, whatWeBringRef, contactUsRef]}
            />
        </>
    )
}

export default HowItWorks;