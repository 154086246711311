
import React, { useState, useEffect, useReducer } from 'react';
import requestDemo from '../../assets/common/requestDemo.png';
import ReCAPTCHA from "react-google-recaptcha";
import Submitted from './submitted.jsx';
import './contactUs.scss';
import axios from 'axios';
import {AuthService} from "../../auth";
import { useNavigate } from 'react-router-dom';
import { useRef } from 'react';

const formReducer = (state, event) => {
    if (event.reset) {
        return {
            first_name: '',
            last_name: '',
            email: '',
            phone_number: '',
            company: '',
            industry: '',
            message: '',
        }
    }
    return {
        ...state,
        [event.name]: event.value
    }
}

const ContactUs = () => {
    let navigate = useNavigate();
    const [formData, setFormData] = useReducer(formReducer, {
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        company: '',
        industry: '',
        message: ''
    })
    const [submitted, setSubmitted] = useState(false);
    const recaptchaRef = useRef();
    const [disableBtn, setDisableBtn] = useState(true);

    const handleSubmit = (e) => {
        e.preventDefault();
        const auth = new AuthService();
        const token = auth.getJwtToken();
        const headers = {};
        const data = {...formData};
        headers['Authorization'] = 'Bearer ' + token;
        headers['Content-Type'] = 'application/json';
        // console.log(headers['Authorization'])
        // console.log('data:', data);
        axios.post('https://futurescape.deloitte.com/transportation/api/admin/com/contact/marketing', data, {headers})
        .then( result => {
            console.log('ContactUs posted successfully', result);
            setFormData({reset: true})
            setSubmitted(true);
            handleRedirect();
        }).catch( err => {
            console.log('ContactUs post failed:', err);
        });
    }

    const handleRedirect = () => {
        setTimeout(() => {
            navigate('/', { replace: true })
        }, 3000);
    }

    const handleChange = (event) => {
        if (event.target.name === 'phone_number') {
            setFormData({
                name: event.target.name,
                value: event.target.value.replace(/[^\d]/g, '')
            })
        } else {
            setFormData({
                name: event.target.name,
                value: event.target.value
            })
        }
    }

    const handleRecaptcha = () => { 
        console.log('I am not a robot selected ');
        setDisableBtn(false);
    }

    const generateForm = () => {
        return (
            <>            
            <span className="duborics header_2">Let's Get In Touch</span>
            <br></br><br></br>
            <form className="contactUs_form" onSubmit={handleSubmit}>
                    <span className="form_input">
                        <label>First Name <small className="required">*required</small></label>
                        <input 
                            type="text"
                            name="first_name"
                            value={formData.first_name}
                            onChange = {handleChange}
                            required
                        ></input>
                    </span>
                    <span className="form_input">
                        <label>Last Name</label>
                        <input
                            type="text" 
                            name="last_name"
                            value={formData.last_name}
                            onChange={handleChange}
                        ></input>
                    </span>
                    <span className="form_input">
                        <label>Email Address <small className="required">*required</small></label>
                        <input 
                            type="email"  
                            name="email" 
                            value={formData.email}
                            onChange={handleChange}
                            size="30"
                            required
                    ></input>
                    </span>
                    <span className="form_input">
                        <label>Phone Number</label>
                        <input 
                            type="phone" 
                            name="phone_number"
                            value={formData.phone_number}
                            onChange={handleChange}
                        ></input>
                    </span>
                    <span className="form_input">
                        <label>Company / Org Name</label>
                        <input 
                            type="text" 
                            name="company" 
                            value={formData.company}
                            onChange={handleChange}
                        ></input>
                    </span>
                    <span className="form_input">
                        <label>Industry</label>
                        <input 
                            type="text" 
                            name="industry" 
                            value={formData.industry}
                            onChange={handleChange}
                        ></input>
                    </span>
                    <span className="form_input last">
                        <label>Message<small className="required">*required</small></label>
                        <textarea 
                            name="message" 
                            rows="20"
                            value={formData.message}
                            onChange={handleChange}
                            required
                        ></textarea>
                    </span>
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="6Lfhh-kjAAAAABCPECibIVXHTMz_hpjVQb6CCgjF"
                        onChange={handleRecaptcha}
                    />
                    <div className="submit_container">
                        <button
                            className="submit_btn" 
                            type="submit"
                            onClick={(e) => console.log('clicked',e)}
                            disabled={disableBtn}
                        >Submit</button>
                    </div>
                </form>
            </>
        )
    }

    return (
        <div>
        <div id="overlay"></div>
        <section className="contactUs_section section">
            {/* <div className="contactUs_container"> */}
                {
                    submitted ? <Submitted /> :  generateForm()
                }
            {/* </div> */}
        </section>
    </div>
    )
}

export default ContactUs;
