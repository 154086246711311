import infrastructure from '../assets/solutions/infrastructure.png';

function mobilityGrid () {
    return ({
        'E-bikes': {
            img: "https://www2.deloitte.com/us/en/insights/industry/technology/smart-micromobility-e-bikes/_jcr_content/root/responsivegrid_380572564/advanced_image.coreimg.95.800.png/1648734371464/in175240-figure1.png",
            content: 'E-bikes merge into the fast lane',
            link: 'https://www2.deloitte.com/us/en/insights/industry/technology/smart-micromobility-e-bikes.html'
        },
        'Infrastructure': {
            img: infrastructure,
            content: `Deloitte's future of infrastructure survey`,
            link: 'https://www2.deloitte.com/us/en/insights/industry/public-sector/future-of-infrastructure.html'
        },
        'Automation': {
            img: 'https://www2.deloitte.com/content/dam/insights/articles/7099_CIC-Future-movement-of-goods/primary/7099_banner_1920x630.jpg/jcr:content/renditions/cq5dam.web.1920.400.jpeg',
            content: 'The journey towards a thouchless network through intelligent automation',
            link: 'https://www2.deloitte.com/us/en/insights/focus/transportation/the-role-of-intelligent-automation-in-the-movement-of-goods.html'
        },
        'Autonomous_Vehicle': {
            img: 'https://www2.deloitte.com/content/dam/insights/us/articles/6699_cir-transportation-agencies-of-the-future/images/6699_banner.jpg/jcr:content/renditions/cq5dam.web.1440.660.jpeg',
            content: 'The transportation agency of the future',
            link: 'https://www2.deloitte.com/us/en/insights/focus/future-of-mobility/future-of-transportation-agencies.html'
        },
        'Transportation': {
            img: 'https://www2.deloitte.com/content/dam/insights/articles/de114079-forget-the-wheel/primary/DI_Forget-the-wheel_1920x630.png/jcr:content/renditions/cq5dam.web.1920.400.jpeg',
            content: 'Forget the wheel',
            link: 'https://www2.deloitte.com/us/en/insights/industry/automotive/reinventing-autonomous-vehicle-interiors.html'
        },
        'Transportation_trend': {
            img: 'https://www2.deloitte.com/content/dam/insights/us/articles/6369_Transportation-Trends-2020/images/6369_1440x660.jpg/jcr:content/renditions/cq5dam.web.1440.660.jpeg',
            content: 'Envisioning transportation in a post-COVID-19 world',
            link: 'https://www2.deloitte.com/us/en/insights/multimedia/podcasts/transportation-trends.html'
        },
        'Autonomous_truck': {
            img: 'https://www2.deloitte.com/content/dam/insights/articles/7031_CIR-Autonomous-trucking-adoption/primary/7031_Banner.jpg/jcr:content/renditions/cq5dam.web.1920.400.jpeg',
            content: 'Autonomous trucks lead the way',
            link: 'https://www2.deloitte.com/us/en/insights/focus/future-of-mobility/autonomous-trucks-lead-the-way.html'
        },
        'Air_Mobility': {
            img: 'https://www2.deloitte.com/content/dam/insights/us/articles/6839_eri-aviation-2035/images/6839_banner.jpg/jcr:content/renditions/cq5dam.web.1440.660.jpeg',
            content: 'Advanced air mobility',
            link: 'https://www2.deloitte.com/us/en/insights/industry/aerospace-defense/advanced-air-mobility.html',
        },
        'inteligent_Supply_Chain': {
            img: 'https://www2.deloitte.com/content/dam/insights/articles/7098_CIC-Future-movement-of-goods/primary/7098_banner_1920x630.jpg/jcr:content/renditions/cq5dam.web.1920.400.jpeg',
            content: 'Enabling holistic decision-making to create a more inteligent network',
            link: 'https://www2.deloitte.com/us/en/insights/focus/transportation/intelligent-supply-chain-movement-of-goods.html'
        },
        'Electric_Vehicle': {
            img: 'https://www2.deloitte.com/content/dam/insights/us/articles/22869-electric-vehicles/images/Electric-Vehicles-_1440x660.jpg/jcr:content/renditions/cq5dam.web.1440.660.jpeg',
            content: 'Electric vehicles',
            link: 'https://www2.deloitte.com/us/en/insights/focus/future-of-mobility/electric-vehicle-trends-2030.html'
        },
        'Software': {
            img: 'https://www2.deloitte.com/content/dam/insights/us/articles/22951_software-is-transforming-the-automotive-world/images/Software-is-transforming-the-automotive-world_1440x660.jpg/jcr:content/renditions/cq5dam.web.1440.660.jpeg',
            content: 'Software is transforming the automotive world',
            link: 'https://www2.deloitte.com/us/en/insights/focus/future-of-mobility/pure-play-software-in-automotive-industry.html',
        },
        'Mobility': {
            img: 'https://www2.deloitte.com/content/dam/insights/us/articles/automotive-news_industry-4-0-in-automotive/6470_whos-going-to-pay/images/6470_banner.png/jcr:content/renditions/cq5dam.web.1440.660.jpeg',
            content: `Who's going to pay for the future of mobility?`,
            link: 'https://www2.deloitte.com/us/en/insights/industry/automotive/will-consumers-pay-for-automotive-industry-innovation.html'
        }
    })
}

export default mobilityGrid;