import React, { useState, useEffect, useRef } from 'react';
import '../solutions/solutions.scss';
import sectionDivider from '../../assets/solutions/section_divider.png';
import fs_mobility from '../../assets/solutions/details/futurescape_mobility.png';
import mobilityGrid from '../../data/mobilityGrid';
import LinkGrid from '../linkGrid/linkGrid';
import ContactSection from '../contactSection/contactSection';
import Scroll from '../scroll/scroll';
import QuoteBox from "../quoteBox/quoteBox";

const Mobility = () => {   
    const [linkGridData, setLinkGridData] = useState({});
    const introRef = useRef();
    const mobilityRef = useRef();
    const leadershipRef = useRef();
    const contactUsRef = useRef();

    useEffect(() => {
        let data = mobilityGrid();
        setLinkGridData(data);
    },[])

    return (
        <>
        <div id="overlay"></div>
        <section>
            <section id="intro" className="section" ref={introRef}>
                <div className="middle_wide auto">
                    <span className="catchphrase duborics text_middle">
                        Industry Solutions: <br/> Mobility
                    </span>
                    <iframe
                        src="https://www.youtube-nocookie.com/embed/_3raTuUgVto" 
                        title="FutureScapeTM: Human-Centered Digital Twin for Transportation Planning" 
                        frameBorder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowFullScreen
                        className="video mobility_video"
                    ></iframe>
                    <QuoteBox>
                        The way people and goods move is changing. As the mobility ecosystem takes shape,
                        long-standing industry dynamics are being upended, and new opportunities are emerging.
                        <br/><br/>
                        With simulation-powered planning, transportation agencies can transform how they plan
                        and design seamless, integrated mobility that is more personalized, convenient,
                        accessible, and inclusive—not just for local communities—but for entire regions.
                    </QuoteBox>
                </div>
            </section>
            <section id="mobility" className="section" ref={mobilityRef}>
                <div className="solution_section_title">
                    <span className="header_3">Futurescape for mobility</span>
                    <img src={sectionDivider} alt=""></img>
                </div>
                <div className="section_content text">
                    <span>
                        Transportation agencies are being asked new questions that require a predictive analytics 
                        capability that goes beyond aggregate measures. Instead, they need to analyze who is using 
                        the transportation network and what choices they make. 
                        <br/><br/>
                        Many transportation agencies are using FutureScape for predictive, traveler-centered 
                        sketch-planning to iteratively evaluate the impact of proposed projects, investments, 
                        and entire portfolios. 
                    </span>
                    <div className="solution_content_box_row">
                        <div className="solution_box white">
                            <span className="bold">FutureScape for <span className="green">Mobility</span> is designed to:</span>
                            <ul className="light">
                                <li>Simulate people and travel behavior across multiple transportation modes</li>
                                <li>Run rapid simulations early and often to assess the impact of projects</li>
                                <li>Test combinations of projects to optimize planning portfolios</li>
                            </ul>
                            <span className="bold">…so you can assess the impact of innovative, multimodal solutions against complex challenges (e.g., congestion, equity).</span>
                        </div>
                        <div className="solution_content_box_image">
                            <img src={fs_mobility} alt=""></img>
                        </div>
                    </div>
                </div>
            </section>
            <section id="leadership" className="section" ref={leadershipRef}>
                <div className="solution_section_title">
                    <span className="header_3">Thought leadership</span>
                    <img src={sectionDivider} alt=""></img>
                </div>
                <LinkGrid data={linkGridData} />
            </section>
        </section>
        <section id="contactUs" className="section" ref={contactUsRef}>
            <ContactSection />
        </section> 
        <Scroll 
            scrollSection={[introRef, mobilityRef, leadershipRef, contactUsRef]}
        />
    </>  
    )
}

export default Mobility;