import React, { useEffect, useState, useRef } from 'react';
import arrowUp from '../../assets/common/mobile_arrow.png';
import './scroll.scss';

const Scroll = ({behavior = 'smooth', scrollSection, mobileOnly = false}) => {
    const [active, setActive] = useState();
    const scrolling = useRef();

    const handleScroll = (ref) => {
        window.scrollTo({
            top: ref.current.offsetTop,
            behavior
        })
        scrolling.current = true;
        setActive(ref.current.id);
    }

    const handleMobileScroll = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    const handleFollow = () => {
        const position = window.pageYOffset;
        scrollSection.forEach((section) => {
            if (section.current.offsetTop - 100 < position && section.current.offsetTop + section.current.clientHeight > position) {
                setActive(section.current.id);
            }
        })
    }


    useEffect(() => {
        window.addEventListener('scroll', handleFollow);
        setActive('intro');
        return () => {
            window.removeEventListener('scroll', handleFollow)
        }
    },[])

    return (
        <> 
            {   !mobileOnly && 
                <div className="scroll">
                {
                    scrollSection.map((section, index) => {
                        if (section.current) {
                            return <span className={"scroll_btn" + (section.current.id == active ? ' active' : '')} key={index} onClick={() => handleScroll(section)}></span>
                        }
                    })
                }
                </div>
            }
            <div className="scroll_mobile">
                <button id="scroll_up_btn">
                    <img 
                        src={arrowUp}
                        alt="scroll To Top Button" 
                        className="scroll_up_img"
                        onClick={handleMobileScroll}
                    />
                </button>
            </div>
        </>
    )
}

export default Scroll;
