export const SolutionContent = [
    {
        id: 'mobility',
        title: 'Mobility',
        content: 'Use simulation-powered planning to transform how you predict, plan, design, and deliver an efficient, integrated mobility experience for residents in your region.',
        link: '/mobility',
    },
    {
        id: 'supplyChain',
        title: 'Supply Chain',
        content: 'Evaluate, stress test, and strategize for massive, multi-network supply chains to withstand unexpected disruptions and adapt to the latest advancements.',
        link: '/supplyChain',
    },
    {
        id: 'preparedness',
        title: 'Preparedness',
        content: 'Digitally explore and test how disruptions caused by natural disasters would impact the operations of an entire city and its population.',
        link: '/preparedness'
    },
    {
        id: 'financialNetworks',
        title: 'Financial Networks',
        content: 'Stress test and help predict risks in interconnected financial ecosystems to prepare and strengthen your financial institution against future disruptions.',
        link: '/financialNetworks'
    },
    {
        id: 'militaryCampaign',
        title: 'Military Campaign',
        content: 'Evaluate the holistic effects of changes to war-fighting concepts, capabilities, and resource allocations to discover optimal strategies to improve readiness and resiliency.',
        link: '/militaryCampaignAnalytics'
    },
    {
        id: 'customNetworks',
        title: 'Custom Networks',
        content: 'Don’t see a FutureScape solution for your industry, or interested in a custom simulation?',
        link: '/contactUs'
    }
]