
import { useEffect, useState, useRef } from 'react';
import Scroll from '../scroll/scroll';

import QuoteBox from "../quoteBox/quoteBox";
import sectionDivider from '../../assets/solutions/section_divider.png';
import fs_campaignAnalytics from '../../assets/solutions/details/futurescape_campaignAnalytics.png';
import ContactSection from '../contactSection/contactSection';


const MilitaryCampaign = () => {
    const introRef = useRef();
    const militaryCampaignRef = useRef();
    const contactUsRef = useRef();

    useEffect(() => {},[])

    return (
        <>
            <section id="intro" className="section" ref={introRef}>
                <div className="middle_wide auto">
                    <span className="catchphrase duborics text_middle">
                        Industry Solutions: <br/> Military Campaign Analytics
                    </span>
                    <QuoteBox>
                        It is increasingly difficult for the U.S. military to assume technological superiority and
                        uncontested environments on the global stage.
                        As the era of great competition begins, concepts and capabilities must be modernized.
                        <br /><br />
                        But where to start? With simulation-powered planning, Defense organizations can emulate a contested
                        environment and iteratively test the impact of new strategies and technologies.
                    </QuoteBox>
                </div>
            </section>
            <section id="mobility" className="section" ref={militaryCampaignRef}>
                <div className="solution_section_title">
                    <span className="header_3">Futurescape for campaign analytics</span>
                    <img src={sectionDivider} alt=""></img>
                </div>
                <div className="section_content">
                    <span className="text">
                    Defense operations rely on a complex system-of-systems that is vulnerable to disruption. 
                    When evaluating concepts and capabilities, Defense organizations require predictive analytics 
                    that move beyond aggregate measures to explore the conditions that give rise to a distribution
                    of readiness and resiliency outcomes. 
                    <br></br><br></br>
                    Defense agencies use FutureScape to assert analytics-driven recommendations on how to improve 
                    performance in areas like logistics, intelligence, and communications.
                    </span>
                    <div className="solution_content_box_row">
                        <div className="solution_box white text">
                            <span className="bold">FutureScape for <span className="green">Campaign Analytics</span> is designed to:</span>
                            <ul className="light">
                                <li>Simulate military functions (e.g., multi-modal logistics) in a contested environment</li>
                                <li>Model data flows between military services, agencies, and industry to study emergent effects of changes in data availability, latency, and accuracy</li>
                                <li>Explore the cascading effects of physical and digital disruptions</li>
                            </ul>
                            <span className="bold">…so you can test combinations of concepts and capabilities when prioritizing investments and operational strategies.</span>
                        </div>
                        <div className="solution_content_box_image">
                            <img src={fs_campaignAnalytics} alt=""></img>
                        </div>
                    </div>
                </div>
            </section>
            <section id="contactUs" ref={contactUsRef}>
                <ContactSection />
            </section> 
            <Scroll
                scrollSection={[introRef, militaryCampaignRef, contactUsRef]}
            />
        </>
    )
}

export default MilitaryCampaign;