import './linkGrid.scss';

const LinkGrid = ({data}) => {
    return (
        <div className="leadership_grid">
            {
                Object.keys(data).map((key) => {
                    return (
                        <div className="leadership_grid_item" key={key}>
                            <img src={`${data[key].img}`} alt={`${key}`} />
                            <a href={`${data[key].link}`} target="_blank">{data[key].content}</a>
                        </div>
                    )
                })
            }
    </div>
    )
}

export default LinkGrid;