import React, { useState, useEffect, useRef } from 'react';
import sectionDivider from '../../assets/solutions/section_divider.png';
import fs_financialNetwork from '../../assets/solutions/details/futurescape_financial.png';
import Scroll from '../scroll/scroll';
import getFinancialNetworkData from '../../data/financialNetwork';
import LinkGrid from '../linkGrid/linkGrid';
import ContactSection from '../contactSection/contactSection';
import QuoteBox from "../quoteBox/quoteBox";

const FinancialNetwork = () => {
    const [financialData, setFinancialData] = useState({});
    const introRef = useRef();
    const financialRef = useRef();
    const leadershipRef = useRef();
    const contactUsRef = useRef();

    useEffect(() => {
        let data = getFinancialNetworkData();
        setFinancialData(data);
    },[])

    return (
        <>
        <section>
            <section id="intro" className="section" ref={introRef}>
                <div className="middle_wide auto">
                    <span className="catchphrase duborics text_middle">
                        Industry Solutions: <br/> Financial Networks
                    </span>
                    <QuoteBox>
                        Financial networks are made up of thousands of entities and complex relationships,
                        making it profoundly difficult to understand the cascading effects of an event or
                        disruption on an entire financial ecosystem.
                        <br/><br/>
                        With FutureScape simulations, organizations can evaluate relationships and assess
                        risks in non-spatial networks, measure the efficacy of proactive regulatory action,
                        and explore historical catastrophic financial events to harden against risks.
                    </QuoteBox>
                </div>
            </section>
            <section id="mobility" className="section" ref={financialRef}>
                <div className="solution_section_title">
                    <span className="header_3">Futurescape for financial networks</span>
                    <img src={sectionDivider} alt=""></img>
                </div>
                <div className="section_content text">
                    <span>
                        FutureScape provides a forward-looking tool to predict risks in interconnected 
                        financial ecosystems and identify causes of possible emergent phenomena. 
                        FutureScape simulates financial shocks and risk scenarios to stress test entire 
                        financial networks.
                        <br/><br/>
                        Use FutureScape to build digital twins of U.S. financial markets to evaluate, 
                        stress test, and predict risks to foster resilience for your financial institution.
                    </span>
                    <div className="solution_content_box_row">
                        <div className="solution_box white text">
                            <span className="bold">FutureScape for <span className="green">Financial Networks</span> is designed to:</span>
                            <ul className="light">
                                <li>Simulate disruptions to individual or multiple institutions to see the ripple effect on an entire financial ecosystem</li>
                                <li>Model subsidiary/parental and lending relationships between institutions, as well as the behavioral logic for these institutions</li>
                                <li>Stress test financial networks by simulating shocks and risk scenarios to identify vulnerabilities</li>
                            </ul>
                            <span className="bold">… so you can run rapid simulations early and often to assess the impact of disruptions on your financial network.</span>
                        </div>
                        <div className="solution_content_box_image">
                            <img src={fs_financialNetwork} alt=""></img>
                        </div>
                    </div>
                </div>
            </section>
            <section id="leadership" className="section" ref={leadershipRef}>
                <div className="solution_section_title">
                    <span className="header_3">Thought leadership</span>
                    <img src={sectionDivider} alt=""></img>
                </div>
                <LinkGrid data={financialData} />
            </section>
        </section>
        <section id="contactUs" ref={contactUsRef}>
            <ContactSection />
        </section> 
        <Scroll 
            scrollSection={[introRef, financialRef, leadershipRef, contactUsRef]}
        />
    </>        
    )
}

export default FinancialNetwork;