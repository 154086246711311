import './solutions.scss';
import mobility from '../../assets/solutions/mobility.png';
import supplyChain from '../../assets/solutions/supplyChain.png';
import preparedness from '../../assets/solutions/preparedness.png';
import customNetworks from '../../assets/solutions/customNetworks.png';
import financialNetworks from '../../assets/solutions/financialNetworks.png';
import backgroundLine from '../../assets/solutions/background_lines_2.png';
import ContactSection from '../contactSection/contactSection';
import campaignAnalytics from '../../assets/solutions/campaignAnalytics.png';
import { Link } from 'react-router-dom';
import Scroll from '../scroll/scroll';
import {SolutionContent} from "./solutionContent";

const IMAGE_URLS = {
    'mobility': mobility,
    'supplyChain': supplyChain,
    'preparedness': preparedness,
    'financialNetworks': financialNetworks,
    'militaryCampaign': campaignAnalytics,
    'customNetworks': customNetworks,
}

const Solutions = () => {
    return (
        <>
            <div id="overlay"></div>
            <section>
                <section id="intro" className="section">
                    <div className="middle_wide auto">
                        <span className="catchphrase text_middle duborics">Industry Solutions</span>
                        <span className="section_summary text">
                            FutureScape offers a fast, low-risk way to evaluate strategies and disruptions that couldn’t 
                            otherwise be tested in the real world. It generates industry-specific insights to help your 
                            organization meet its needs and prepare for the future of your industry.  
                        </span>
                    </div>
                </section>
                <section className="solution_categories section">
                    {
                        SolutionContent.map((content) => {
                            return (
                                <div className="solution_category" id={content.id}>
                                    <img src={IMAGE_URLS[content.id]} alt={content.title}></img>
                                    <div className="solution_details">
                                        <div className="solution_details_content">
                                            <div className="header_3">{content.title}</div>
                                            <div className="text">
                                                {content.content}
                                            </div>
                                        </div>
                                        <Link to={content.link}>
                                            <button className="section_btn text">Learn More</button>
                                        </Link>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className="categoriesLine_wrapper">
                        <img src={backgroundLine} alt=""></img>
                    </div>
                </section>
                <section id="contactUs">
                    <ContactSection/>
                </section>
                <Scroll
                    scrollSection={[]}
                    mobileOnly="true"
                />
            </section>
            </>
            )
            }

            export default Solutions;