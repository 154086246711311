import sectionDivider from '../../assets/solutions/section_divider.png';
import fs_supplyChain from '../../assets/solutions/details/futurescape_supplyChain.png';
import LinkGrid from '../linkGrid/linkGrid';
import Scroll from '../scroll/scroll';
import { useEffect, useState } from 'react';
import getSupplyChainData from '../../data/supplyChain';
import { useRef } from 'react';
import ContactSection from '../contactSection/contactSection';
import QuoteBox from "../quoteBox/quoteBox";
import Slider from '../slider/slider';
import generateSupplyChainSliderData from "./supplyChainSliderData";


const SupplyChain = () => {
    const [supplyChainData, setSupplyChainData] = useState({});
    const introRef = useRef();
    const leadershipRef = useRef();
    const supplyChainRef = useRef();
    const contactUsRef = useRef();
    const useCaseRef = useRef();
    const [sliderData, setSliderData] = useState({});

    useEffect(() => {
        let data = getSupplyChainData();
        let sliderData = generateSupplyChainSliderData();
        setSliderData(sliderData);
        setSupplyChainData(data);
    },[])

    return (
    <main>
        <section id="intro" className="section" ref={introRef}>
            <div className="middle_wide auto">
                <span className="catchphrase duborics text_middle">
                    Industry Solutions: <br/> Supply Chain 
                </span>
                <QuoteBox>
                    In the last decade, the world has been faced with unprecedented disruptions stemming
                    from the increased interconnectedness of global communities, growing geopolitical tensions,
                    and the rise of natural disasters.
                    <br /> <br />
                    Disruptions pose tremendous risk to organizations; how do they impact your supply chain network?
                </QuoteBox>
            </div>
        </section>
        <section id="mobility" className="section" ref={supplyChainRef}>
            <div className="solution_section_title">
                <span className="header_3">Futurescape for supply chain</span>
                <img src={sectionDivider} alt=""></img>
            </div>
            <div className="section_content">
                <span className="text">
                    FutureScape provides simulation-powered, predictive insights to transform how organizations make decisions for their supply chains.
                    FutureScape’s simulation platform provides a digital testbed to quantitatively evaluate the impact of potential disruptions on your
                    network and help you harden your supply chain before these disruptions occur.
                    <br /><br />
                    FutureScape is designed to simulate the unpredictable ripple effects that arise when disruptions, such as weather events or sanctions,
                    cascade through a complex network. Organizations can use FutureScape to iteratively simulate the effects of these disruptions to aid
                    in designing resiliency and mitigation strategies for their supply chains.
                </span>
                <div className="solution_content_box_row">
                    <div className="solution_box white text">
                        <span className="bold">FutureScape for <span className="green">Supply Chain</span> is designed to:</span>
                        <ul className="light">
                            <li>Simulate the movement of goods across multiple networks</li>
                            <li>Run simulations rapidly to assess the impact of disruptions early and often</li>
                            <li>Test combinations of changes to optimize supply chain networks</li>
                        </ul>
                        <span className="bold">...so you can improve your supply chain’s resiliency in today’s increasingly complex world.</span>
                    </div>
                    <div className="solution_content_box_image">
                        <img src={fs_supplyChain} alt=""></img>
                    </div>
                </div>
            </div>
        </section>
        <section id="useCase" className="section" ref={useCaseRef}>
            <div className="solution_section_title">
                <span className="header_3">Futurescape use-cases</span>
                <img src={sectionDivider} alt=""></img>
            </div>
            <Slider data={sliderData} sliderType="default"></Slider>
        </section>
        <section id="leadership" className="section" ref={leadershipRef}>
            <div className="solution_section_title">
                <span className="header_3">Thought leadership</span>
                <img src={sectionDivider} alt=""></img>
            </div>
            <LinkGrid 
                data={supplyChainData}
            />
        </section>
        <section id="contactUs" ref={contactUsRef}>
            <ContactSection />
        </section> 
        <Scroll
             scrollSection={[introRef, supplyChainRef,  useCaseRef, leadershipRef,contactUsRef]}
        />
    </main>
    )
}

export default SupplyChain;