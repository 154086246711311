import React, { useState, useEffect } from 'react';
import contactUs from '../../assets/common/contactUs.png';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import './contactSection.scss';

const ContactSection = () => {
    return (
        <>
        <div className="section_content middle">
        <div className="main_section_title header_2">
            Let's get in touch
        </div>
        <div className="text">
            Contact us to learn how FutureScape can transform how your 
            organization makes its toughest decisions.
        </div>
        <Link to="/contactUs">
            <button className="section_btn main text">Request Demo</button>
        </Link>
        </div>
        <div className="contactUs_img">
            <img src={contactUs} alt=""></img>
        </div>
        </>
    )
}

export default ContactSection;